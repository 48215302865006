import { createSlice } from "@reduxjs/toolkit";
import { loginSuccess, registerSuccess } from "./extraReducers";
import { setTokens, logout } from "./reducers";

const initialState = {
  authToken: null,
  refreshToken: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens: setTokens,
    logout,
  },
  extraReducers: (builder) => {
    // builder
    //   //Login Matchers
    //   .addMatcher(authAPI.endpoints.login.matchFulfilled, loginSuccess)
    //   //Register Matchers
    //   .addMatcher(authAPI.endpoints.register.matchFulfilled, registerSuccess);
  },
});

export const { setAuthTokens } = slice.actions;
export default slice.reducer;
