import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const others = {
  id: 'others',
  title: 'Others',
  type: 'group',
  userRoles: ['Super Admin'],
  permissions: [
    'view-content-types',
    'view-notifications',
    'view-faqs',
    'view-feedbacks',
    'view-terms-and-conditions',
    'view-aboutUs',
    'view-subscriptions',
    'view-coupons',
    'view-privacy-policy'
  ],
  children: [
    {
      id: 'content-types',
      title: 'Content Types',
      type: 'item',
      url: '/content-types',
      icon: ContentPasteIcon,
      breadcrumbs: false,
      permission: 'view-content-types'
    },
    {
      id: 'app-content',
      title: 'App Content',
      type: 'collapse',
      icon: ContentPasteIcon,
      permissions: [
        'view-notifications',
        'view-faqs',
        'view-feedbacks',
        'view-terms-and-conditions',
        'view-aboutUs',
        'view-subscriptions',
        'view-coupons',
        'view-privacy-policy'
      ],
      children: [
        {
          id: 'notifications',
          title: 'Notifications',
          type: 'item',
          url: '/app-content/notifications',
          permission: 'view-notifications'
        },
        {
          id: 'faqs',
          title: 'FAQs',
          type: 'item',
          url: '/app-content/faqs',
          permission: 'view-faqs'
        },
        {
          id: 'feedbacks',
          title: 'Feedbacks',
          type: 'item',
          url: '/app-content/feedbacks',
          permission: 'view-feedbacks'
        },
        {
          id: 'privacy-policy',
          title: 'Privacy Policy',
          type: 'item',
          url: '/app-content/privacy-policy',
          permission: 'view-privacy-policy'
        },
        {
          id: 'terms',
          title: 'Terms and Conditions',
          type: 'item',
          url: '/app-content/terms',
          permission: 'view-terms-and-conditions'
        },
        {
          id: 'about-us',
          title: 'About us',
          type: 'item',
          url: '/app-content/about-us',
          permission: 'view-aboutUs'
        },
        {
          id: 'subscriptions',
          title: 'Subscriptions',
          type: 'item',
          url: '/app-content/subscriptions',
          permission: 'view-subscriptions'
        },
        {
          id: 'coupons',
          title: 'Coupons',
          type: 'item',
          url: '/app-content/coupons',
          permission: 'view-coupons'
        }
      ]
    }
  ]
};

export default others;
