const initialState = {
  authToken: null,
  refreshToken: null
};

export const setTokens = (state, action) => {
  state.authToken = action.payload.authToken;
  state.refreshToken = action.payload.refreshToken;
};

export const logout = (state, action) => {
  state = initialState;
};
