import { baseAPI } from './baseApi';

export const userAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => 'auth',
      providesTags: ['USER']
    }),
    updateUserProfile: builder.mutation({
      query: (payload) => ({
        url: 'auth/updateProfile',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['USER']
    }),
    updateUserProfileImage: builder.mutation({
      query: (payload) => ({
        url: 'auth/updateProfileImage',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['USER']
    }),
    updateUserPassword: builder.mutation({
      query: (payload) => ({
        url: 'auth/updateUserPassword',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['USER']
    }),
    updateUserBankDetails: builder.mutation({
      query: (payload) => ({
        url: 'auth/update-account-detail',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['USER']
    }),
  })
});

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserProfileImageMutation,
  useUpdateUserBankDetailsMutation
} = userAPI;
