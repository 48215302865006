import dashboard from './dashboard';
import user_management from './user-management';
import other from './other';
import administration from './administration';
import transactions from './transactions';
import content_creator from './content-creators';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, content_creator, administration, user_management, transactions, other]
};

export default menuItems;
