import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from './slices/auth/slice';
import { baseAPI } from 'services/baseApi';
import customizationReducer from './customizationReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    customization: customizationReducer,
    [baseAPI.reducerPath]: baseAPI.reducer
  },
  middleware: (defaultMiddleware) => defaultMiddleware().concat(baseAPI.middleware)
});

export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;
export default store;
