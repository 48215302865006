import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RequireAuth from 'utils/require-auth';
// import ImageGallery from 'ui-component/image-gallery/ImageGallery';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// sample page routing
const RolesManagement = Loadable(lazy(() => import('views/roles-management')));
const AdminUsers = Loadable(lazy(() => import('views/admin-users')));
const AssignPermissions = Loadable(lazy(() => import('views/roles-management/assign-permissions')));
const Users = Loadable(lazy(() => import('views/users')));
const GalleryView = Loadable(lazy(() => import('views/image-gallery')));
const PermissionsListing = Loadable(lazy(() => import('views/roles-management/permissions-listing')));
const PermissionTypes = Loadable(lazy(() => import('views/roles-management/permission-types-listing')));

const AdvertisersListing = Loadable(lazy(() => import('views/user-management/advertisers')));
const ContentCreatorsListing = Loadable(lazy(() => import('views/user-management/content-creators')));
const AppUsersListing = Loadable(lazy(() => import('views/user-management/app-users')));

const ContentTypesListing = Loadable(lazy(() => import('views/content-types')));
const NotificationsListing = Loadable(lazy(() => import('views/app-content/notifications')));
const FAQsListing = Loadable(lazy(() => import('views/app-content/faqs')));
const FeedbackListing = Loadable(lazy(() => import('views/app-content/feedback')));

const PrivacyPolicy = Loadable(lazy(() => import('views/app-content/privacy-policy')));
const TermsAndConditions = Loadable(lazy(() => import('views/app-content/terms')));
const AboutUs = Loadable(lazy(() => import('views/app-content/about-us')));
const Subscriptions = Loadable(lazy(() => import('views/app-content/subscriptions')));
const Coupons = Loadable(lazy(() => import('views/app-content/coupons')));
const CouponCodes = Loadable(lazy(() => import('views/app-content/coupons/coupon-codes')));
const Transactions = Loadable(lazy(() => import('views/transactions/transactions')));
const UserSubscriptions = Loadable(lazy(() => import('views/transactions/subscriptions')));

const ContentCreatorProfile = Loadable(lazy(() => import('views/content-creator-profile')));

const ContentCreatorCoupons = Loadable(lazy(() => import('views/content-creator-coupons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <RequireAuth>
          <DashboardDefault />
        </RequireAuth>
      )
    },
    {
      path: 'dashboard',
      element: (
        <RequireAuth>
          <DashboardDefault />
        </RequireAuth>
      )
    },
    {
      path: 'roles-management',
      element: (
        <RequireAuth>
          <RolesManagement />
        </RequireAuth>
      )
    },
    {
      path: 'admin-users',
      element: (
        <RequireAuth>
          <AdminUsers />
        </RequireAuth>
      )
    },
    {
      path: 'assign-permissions',
      element: (
        <RequireAuth>
          <AssignPermissions />
        </RequireAuth>
      )
    },
    {
      path: 'users',
      element: (
        <RequireAuth>
          <Users />
        </RequireAuth>
      )
    },
    {
      path: 'advertisers-list',
      element: (
        <RequireAuth>
          <AdvertisersListing />
        </RequireAuth>
      )
    },
    {
      path: 'content-creators-list',
      element: (
        <RequireAuth>
          <ContentCreatorsListing />
        </RequireAuth>
      )
    },
    {
      path: 'app-users-list',
      element: (
        <RequireAuth>
          <AppUsersListing />
        </RequireAuth>
      )
    },
    {
      path: 'gallery',
      element: (
        <RequireAuth>
          <GalleryView />
        </RequireAuth>
      )
    },
    {
      path: 'content-creator-coupons',
      element: (
        <RequireAuth>
          <ContentCreatorCoupons />
        </RequireAuth>
      )
    },
    {
      path: 'permissions',
      element: (
        <RequireAuth>
          <PermissionsListing />
        </RequireAuth>
      )
    },
    {
      path: 'permission-types',
      element: (
        <RequireAuth>
          <PermissionTypes />
        </RequireAuth>
      )
    },
    {
      path: 'content-types',
      element: (
        <RequireAuth>
          <ContentTypesListing />
        </RequireAuth>
      )
    },
    {
      path: 'notifications',
      element: (
        <RequireAuth>
          <NotificationsListing />
        </RequireAuth>
      )
    },
    {
      path: 'app-content',
      children: [
        {
          path: 'notifications',
          element: (
            <RequireAuth>
              <NotificationsListing />
            </RequireAuth>
          )
        },
        {
          path: 'faqs',
          element: (
            <RequireAuth>
              <FAQsListing />
            </RequireAuth>
          )
        },
        {
          path: 'feedbacks',
          element: (
            <RequireAuth>
              <FeedbackListing />
            </RequireAuth>
          )
        },
        {
          path: 'privacy-policy',
          element: (
            <RequireAuth>
              <PrivacyPolicy />
            </RequireAuth>
          )
        },
        {
          path: 'terms',
          element: (
            <RequireAuth>
              <TermsAndConditions />
            </RequireAuth>
          )
        },
        {
          path: 'about-us',
          element: (
            <RequireAuth>
              <AboutUs />
            </RequireAuth>
          )
        },
        {
          path: 'subscriptions',
          element: (
            <RequireAuth>
              <Subscriptions />
            </RequireAuth>
          )
        },
        {
          path: 'coupons',
          element: (
            <RequireAuth>
              <Coupons />
            </RequireAuth>
          )
        },
        {
          path: 'coupons/codes',
          element: (
            <RequireAuth>
              <CouponCodes />
            </RequireAuth>
          )
        }
      ]
    },
    {
      path: 'transactions',
      element: (
        <RequireAuth>
          <Transactions />
        </RequireAuth>
      )
    },
    {
      path: 'subscriptions',
      element: (
        <RequireAuth>
          <UserSubscriptions />
        </RequireAuth>
      )
    },
    {
      path: 'content-creator-profile',
      element: (
        <RequireAuth>
          <ContentCreatorProfile />
        </RequireAuth>
      )
    }
  ]
};

export default MainRoutes;
