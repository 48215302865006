// assets
// import { IconKey } from '@tabler/icons';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// constant
const icons = {
  ReceiptLongIcon,
  SubscriptionsIcon
};

const transactions = {
  id: 'transactions',
  title: 'Transactions',
  //   caption: 'Pages Caption',
  type: 'group',
  userRoles: ['Super Admin'],
  permissions: ['view-user-subscriptions', 'view-user-transactions'],
  children: [
    {
      id: 'subscriptions',
      title: 'Subscriptions',
      type: 'item',
      url: '/subscriptions',
      icon: icons.SubscriptionsIcon,
      breadcrumbs: false,
      permission: 'view-user-subscriptions'
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'item',
      url: '/transactions',
      icon: icons.ReceiptLongIcon,
      breadcrumbs: false,
      permission: 'view-user-transactions'
    }
  ]
};

export default transactions;
