// assets
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// constant
const icons = { CollectionsOutlinedIcon, ReceiptLongIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const content_creator = {
  id: 'gallery',
  // title: 'Gallery',
  type: 'group',
  userType: 3,
  permissions: ['view-gallery', 'view-content-creator-coupons'],
  children: [
    {
      id: 'gallery',
      title: 'Gallery',
      type: 'item',
      url: '/gallery',
      icon: icons.CollectionsOutlinedIcon,
      breadcrumbs: false,
      permission: 'view-gallery'
    },
    {
      id: 'content-creator-coupons',
      title: 'Coupons',
      type: 'item',
      url: '/content-creator-coupons',
      icon: icons.ReceiptLongIcon,
      breadcrumbs: false,
      permission: 'view-content-creator-coupons'
    }
  ]
};

export default content_creator;
