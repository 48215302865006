// assets
// import { IconKey } from '@tabler/icons';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';

// constant
const icons = {
  AdminPanelSettingsOutlinedIcon,
  GroupRoundedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const administration = {
  id: 'administration',
  title: 'Administration',
  // caption: 'Pages Caption',
  type: 'group',
  userRoles: ['Super Admin'],
  permissions: ['view-roles', 'view-system-users'],
  children: [
    {
      id: 'roles',
      title: 'Roles Management',
      type: 'item',
      url: '/roles-management',
      icon: icons.AdminPanelSettingsOutlinedIcon,
      breadcrumbs: false,
      permission: 'view-roles'
    },
    {
      id: 'admin-users',
      title: 'Admin Users',
      type: 'item',
      url: '/admin-users',
      icon: icons.GroupRoundedIcon,
      breadcrumbs: false,
      permission: 'view-system-users'
    }
    // {
    //   id: 'users',
    //   title: 'Users',
    //   type: 'item',
    //   url: '/users',
    //   icon: icons.GroupRoundedIcon,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'administration',
    //   title: 'Administration',
    //   type: 'collapse',
    //   icon: icons.AdminPanelSettingsOutlinedIcon,
    //   children: [
    //     {
    //       id: 'roles',
    //       title: 'Roles Management',
    //       type: 'item',
    //       url: '/pages/login/login3',
    //       target: true
    //     },
    //     {
    //       id: 'admin-users',
    //       title: 'Admin Users',
    //       type: 'item',
    //       url: '/pages/register/register3',
    //       target: true
    //     }
    //   ]
    // }
  ]
};

export default administration;
