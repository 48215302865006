// RTK Query
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { remove_session } from '../utils/functions';

// Tags
export const TAGS = ['ROLES_LIST'];
const BASE_URL = process.env.REACT_APP_APIPATH;

// Create baseQuery instance
const baseQuery = async (args, api, extraOptions) => {
  const response = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = localStorage.userToken;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);
  if (response?.error && response?.error?.status === 401) {
    remove_session();
    window.location.pathname = '/login';
  }
  return response;
};

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

export const baseAPI = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: TAGS,
  endpoints: () => ({})
});
