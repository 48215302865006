import { useEffect, useState } from "react";

import { getUserRole, is_logged_in, user_role } from "./functions";

export const usePermissions = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [userRole, setUserRole] = useState({});

  // useEffect(() => {
  //   getUserRole().then((res) => {
  //     if (res && res.status === 200) {
  //       const userData = res?.data || JSON.parse(localStorage.getItem("userRole"));
  //       const userRole = userData && userData.role;
  //       const permissions = userRole && userRole.permissions;
  //       setUserPermissions(permissions);
  //       setUserRole(userRole);
  //     }
  //   });
  // }, []);
  useEffect(() => {
    if (is_logged_in() && !user_role()) {
      getUserRole().then((res) => {
        if (res && res.status === 200) {
          const userData = res?.data || JSON.parse(localStorage.getItem("userRole"));
          const userRole = userData && userData.role;
          const permissions = userRole && userRole.permissions;
          setUserPermissions(permissions);
          setUserRole(userRole);
        }
      });
    } else {
      const userData = JSON.parse(localStorage.getItem("userRole"));
      const userRole = userData && userData.role;
      const permissions = userRole && userRole.permissions;
      setUserPermissions(permissions);
      setUserRole(userRole);
    }
  }, []);

  const hasPermission = (permission) => {
    if (userRole?.name === "Super Admin") return true;
    return userPermissions?.length > 0 && userPermissions.includes(permission);
  };

  return { hasPermission };
};
