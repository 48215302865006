// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useLazyGetUserProfileQuery } from 'services/userApi';
import { useEffect } from 'react';
import { get_user, set_user } from 'utils/functions';
import { useState } from 'react';
import { usePermissions } from 'utils/usePermissions';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { hasPermission } = usePermissions();
  // const [trigger] = useLazyGetUserProfileQuery();
  // const [userData, setUserData] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const { data } = await trigger(1);
  //       setUserData(data);
  //       set_user(data);
  //     } catch (error) {
  //       console.error('Error fetching user profile', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const userData = get_user();

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        if (userData && (userData?.role === 'Super Admin' || userData?.userType == 1)) {
          return item.userType !== 3 && <NavGroup key={item.id} item={item} />;
        }
        return item?.permissions?.some((permission) => hasPermission(permission)) ? <NavGroup key={item.id} item={item} /> : null;
      // return item?.permissions.some((permission) => hasPermission(permission)) && <NavGroup key={item.id} item={item} />;
      // return item?.userRoles?.includes(userData?.role) && <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
