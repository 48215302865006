import axios from 'axios';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';

export const remove_session = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('user');
  localStorage.removeItem('userRole');
};

export const add_session = (token) => {
  localStorage.setItem('userToken', token);
};

export const is_logged_in = () => {
  return localStorage.userToken;
};

export const set_user = (user) => {
  // Convert the user object to a string
  const userString = JSON.stringify(user);

  // Encrypt the user details using a secret key (replace 'yourSecretKey' with your actual secret key)
  const encryptedUser = CryptoJS.AES.encrypt(userString, '_.UVt{Wi@IBCnmt').toString();

  // Save the encrypted user details to localStorage
  localStorage.setItem('user', encryptedUser);
};

export const user_role = () => {
  return localStorage.userRole ? JSON.parse(localStorage.userRole) : "";
};

export const set_user_role = (userRole) => {
  localStorage.setItem("userRole", JSON.stringify(userRole));
};

// Example of decrypting user details
export const get_user = () => {
  // Retrieve the encrypted user details from localStorage
  const encryptedUser = localStorage.getItem('user');

  if (encryptedUser) {
    try {
      // Decrypt the user details using the secret key
      const bytes = CryptoJS.AES.decrypt(encryptedUser, '_.UVt{Wi@IBCnmt');
      const decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      return decryptedUser;
    } catch (error) {
      remove_session();
    }
  }

  return null;
};

export const current_user = () => {
  return localStorage.user ? JSON.parse(localStorage.user) : '';
};

export const getPaymentIntent = async (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + '/stripe/payment', payload, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const stripePaymentResponse = async (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + '/stripe/addUserSubscription', payload, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const prorationCost = async (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + '/stripe/proration-cost', payload, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateSubscription = async (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + '/stripe/update-subscription', payload, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Download payment

export const getDownloadPaymentIntent = async (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + '/stripe/downloadPayment', payload, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const stripeDownloadPaymentResponse = async (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + '/stripe/downloadPaymentStatusUpdate', payload, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCurrencySymbol = (currencyCode) => {
  try {
    const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode });
    const currencyParts = currencyFormatter.formatToParts(0);
    const currencySymbolPart = currencyParts.find((part) => part.type === 'currency');
    if (currencySymbolPart) {
      return currencySymbolPart.value;
    }
  } catch (error) {
    console.error('Error retrieving currency symbol:', error);
  }

  return ''; // Return an empty string if the currency symbol is not found or there was an error
};

export async function downloadCSVFile(endpoint, fileName, params) {
  const token = localStorage.userToken;
  try {
    const response = await axios.post(`${process.env.REACT_APP_APIPATH}${endpoint}`, {
      responseType: 'blob',
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const blob = new Blob([response.data], { type: 'text/csv' });
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${fileName}.csv`;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    toast.success('CSV downloaded successfully');
  } catch (error) {
    toast.error(error?.message || 'An error occurred while downloading the CSV file');
  }
}

export const getUserRole = async () => {
  try {
    const response = await axios
      .get(process.env.REACT_APP_APIPATH + "/users/getAdminRoleWithPermissions", {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.userToken}` },
      });
    set_user_role(response.data);
    return response;
  } catch (err) {
    return err.response;
  }
};

/////////////////////////////////// Dashboard Functions //////////////////////////////////

// Create a monthly data array for the current year
const currentDate = new Date();
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1; // Adding 1 to get the current month (1-12)

// const RevenueYearlyData = Array.from({ length: 12 }, (_, index) => {
//   const month = index + 1; // Months are 1-indexed (1-12)
//   const revenueEntry = dashboardStats?.webRevenue.find(
//     (entry) => entry._id.year === currentYear && new Date(entry.createdAt).getMonth() + 1 === month
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });

// export const generateYearlyDataArray = (dataArray, xAxisArray) => {
//   const monthNameToNumber = {
//     Jan: 1,
//     Feb: 2,
//     Mar: 3,
//     Apr: 4,
//     May: 5,
//     Jun: 6,
//     Jul: 7,
//     Aug: 8,
//     Sep: 9,
//     Oct: 10,
//     Nov: 11,
//     Dec: 12,
//   };

//   return xAxisArray?.map((monthName) => {
//     const monthNumber = monthNameToNumber[monthName];
//     const revenueEntry = dataArray?.find((entry) => entry?._id.month === monthNumber);
//     return revenueEntry ? revenueEntry?.totalSum : 0;
//   });
// };

export const generateYearlyDataArray = (dataArray, xAxisArray, type) => {
  const monthNameToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };

  return xAxisArray?.map((monthName) => {
    const monthNumber = monthNameToNumber[monthName];
    const monthData = dataArray?.find((entry) => entry?.month === monthNumber);
    if (monthData) {
      return type === 'organic' ? monthData?.organic : monthData?.nonOrganic;
    } else {
      return 0;
    }
  });
};

// Create a thirty days data array for webRevenue
// const RevenueMontlyData = Array.from({ length: 30 }, (_, index) => {
//   const day = index + 1;
//   const revenueEntry = dashboardStats?.webRevenue?.find(
//     (entry) => entry._id.year === currentYear && entry._id.month === currentMonth && new Date(entry.createdAt).getDate() === day
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });
// export const generateMonthlyDataArray = (dataArray) =>
//   Array.from({ length: 30 }, (_, index) => {
//     const day = index + 1;
//     const revenueEntry = dataArray?.find(
//       (entry) => entry._id.year === currentYear && entry._id.month === currentMonth && new Date(entry.createdAt).getDate() === day
//     );
//     return revenueEntry ? revenueEntry.totalSum : 0;
//   });
// export const generateMonthlyDataArray = (dataArray) => {
//   return Array.from({ length: 30 }, (_, index) => {
//     const day = index + 1;
//     const revenueEntry = dataArray?.find((entry) => entry._id.year === currentYear && entry._id.month === currentMonth && entry._id.day === day);
//     return revenueEntry ? revenueEntry.totalSum : 0;
//   });
// };

export const generateMonthlyDataArray = (dataArray, xAxisArray, type) => {
  return xAxisArray?.map((day) => {
    const dayOfMonth = parseInt(day.split(' ')[1], 10); // Extract the day of the month
    const revenueEntry = dataArray?.find((entry) => entry?.day === dayOfMonth);
    if (revenueEntry) {
      return type === 'organic' ? revenueEntry?.organic : revenueEntry?.nonOrganic;
    } else {
      return 0;
    }
  });
};

// Create a seven days data array for webRevenue
// const RevenueWeeklyData = Array.from({ length: 7 }, (_, index) => {
//   const day = currentDate.getDate() - currentDate.getDay() + index;
//   const revenueEntry = dashboardStats?.webRevenue?.find(
//     (entry) =>
//       entry._id.year === currentYear && entry._id.month === currentMonth && entry._id.weekInMonth === currentWeekInMonth && entry._id.day === day
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });

// export const generateWeeklyDataArray = (dataArray, xAxisArray) => {
//   return xAxisArray?.map((day) => {
//     const revenueEntry = dataArray?.find((entry) => entry._id.year === currentYear && entry._id.month === currentMonth && entry._id.day === day);
//     return revenueEntry ? revenueEntry.totalSum : 0;
//   });
// };

// export const generateWeeklyDataArray = (dataArray, xAxisArray) => {
//   const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

//   return xAxisArray?.map((dayName) => {
//     const dayIndex = dayNames?.indexOf(dayName);
//     const day = new Date(currentYear, currentMonth - 1, 1).getDay(); // Get the day of the week for the first day of the current month
//     const targetDay = ((dayIndex - day + 7) % 7) + 1; // Calculate the target day of the week

//     console.log(targetDay, "targetDay");
//     const revenueEntry = dataArray?.find(
//       (entry) => entry?._id.year === currentYear && entry?._id.month === currentMonth && entry?._id.day === targetDay
//     );
//     return revenueEntry ? revenueEntry?.totalSum : 0;
//   });
// };

export const generateWeeklyDataArray = (dataArray, type) => {
  const currentMonth = new Date().getMonth() + 1; // Current month is 1-indexed
  const currentYear = new Date().getFullYear();
  const last7DaysArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date(currentDate);
    day.setDate(currentDate.getDate() - i);
    last7DaysArray.push(day.getDate());
  }

  return last7DaysArray?.map((dayNumber) => {
    const revenueEntry = dataArray?.find(
      (entry) =>
        // entry?._id.year === currentYear &&
        // entry?._id.month === currentMonth &&
        entry?.day === dayNumber
    );
    if (revenueEntry) {
      return type === 'organic' ? revenueEntry?.organic : revenueEntry?.nonOrganic;
    } else {
      return 0;
    }
  });
};

export const generateDailyDataArray = (dataArray, type) => {
  const hoursArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  // Initialize an array to store the results
  const resultArray = [];

  // Create a map to efficiently access totalSum by hour
  const hourTotalSumMap = dataArray?.reduce((acc, entry) => {
    const hour = entry?.hour?.toString().padStart(2, '0');
    acc[hour] = type === 'organic' ? entry?.organic : entry?.nonOrganic;
    return acc;
  }, {});

  // Iterate through the hoursArray and add totalSum if available
  hoursArray.forEach((hour) => {
    const totalSum = hourTotalSumMap?.[hour] || 0; // Ensure that hourTotalSumMap is defined
    resultArray.push(totalSum);
  });
  return resultArray;
};

// For subscriptions arrays

export const generateYearlyDataArraySubscriptions = (dataArray, xAxisArray, type) => {
  const monthNameToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };

  return xAxisArray?.map((monthName) => {
    const monthNumber = monthNameToNumber[monthName];
    const monthData = dataArray?.find((entry) => entry?._id?.month === monthNumber);
    if (monthData) {
      return type === 'amount' ? monthData?.amount : monthData?.numbers;
    } else {
      return 0;
    }
  });
};

export const generateMonthlyDataArraySubscriptions = (dataArray, xAxisArray, type) => {
  return xAxisArray?.map((day) => {
    const dayOfMonth = parseInt(day.split(' ')[1], 10); // Extract the day of the month
    const revenueEntry = dataArray?.find((entry) => entry?._id?.day === dayOfMonth);
    if (revenueEntry) {
      return type === 'amount' ? revenueEntry?.amount : revenueEntry?.numbers;
    } else {
      return 0;
    }
  });
};

export const generateWeeklyDataArraySubscriptions = (dataArray, type) => {
  const currentMonth = new Date().getMonth() + 1; // Current month is 1-indexed
  const currentYear = new Date().getFullYear();
  const last7DaysArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date(currentDate);
    day.setDate(currentDate.getDate() - i);
    last7DaysArray.push(day.getDate());
  }

  return last7DaysArray?.map((dayNumber) => {
    const revenueEntry = dataArray?.find(
      (entry) =>
        // entry?._id.year === currentYear &&
        // entry?._id.month === currentMonth &&
        entry?._id?.day === dayNumber
    );
    if (revenueEntry) {
      return type === 'amount' ? revenueEntry?.amount : revenueEntry?.numbers;
    } else {
      return 0;
    }
  });
};

export const generateDailyDataArraySubscriptions = (dataArray, type) => {
  const hoursArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  // Initialize an array to store the results
  const resultArray = [];

  // Create a map to efficiently access totalSum by hour
  const hourTotalSumMap = dataArray?.reduce((acc, entry) => {
    const hour = entry?._id?.hour?.toString().padStart(2, '0');
    acc[hour] = type === 'amount' ? entry?.amount : entry?.numbers;
    return acc;
  }, {});

  // Iterate through the hoursArray and add totalSum if available
  hoursArray.forEach((hour) => {
    const totalSum = hourTotalSumMap?.[hour] || 0; // Ensure that hourTotalSumMap is defined
    resultArray.push(totalSum);
  });
  return resultArray;
};

// Function to calculate the weekInMonth for a given date
// function getWeekInMonth(date) {
//   const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
//   const daysOffset = firstDayOfMonth.getDay();
//   const currentDay = date.getDate();
//   return Math.ceil((currentDay + daysOffset) / 7);
// }

// Create a thirty days data array for appleRevenue
// const appleRevenueDataArray = Array.from({ length: 30 }, (_, index) => {
//   const day = index + 1;
//   const revenueEntry = appleRevenue.find(
//     (entry) => entry._id.year === currentYear && entry._id.month === currentMonth && new Date(entry.createdAt).getDate() === day
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });

export const generateYearlyXAxisArray = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const currentMonthIndex = new Date().getMonth(); // Get the current month index (0-indexed)
  const pastMonths = [];

  for (let i = 11; i >= 0; i--) {
    const monthIndex = (currentMonthIndex + 12 - i) % 12;
    pastMonths.push(months[monthIndex]);
  }

  return pastMonths;
};

export const generateMonthlyXAxisArray = () => {
  const numDays = 30;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const xAxisArray = Array.from({ length: numDays }, (_, index) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - numDays + index);
    const monthAbbreviation = months[currentDate.getMonth()];
    const dayOfMonth = currentDate.getDate();
    return `${monthAbbreviation} ${dayOfMonth}`;
  });

  const currentDate = new Date();
  const currentMonthAbbreviation = months[currentDate.getMonth()];
  const currentDayOfMonth = currentDate.getDate();
  xAxisArray.push(`${currentMonthAbbreviation} ${currentDayOfMonth}`);

  return xAxisArray;
};

export const generateWeeklyXAxisArray = () => {
  // const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const today = new Date();
  const xAxisArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date();
    day.setDate(today.getDate() - i);
    const dayIndex = day.getDay();
    const formattedDate = `${day.getDate()} ${months[day.getMonth()]}`;
    xAxisArray.push(formattedDate);
  }
  return xAxisArray;
};

export const TwentyFourHoursArray = Array.from({ length: 24 }, (_, index) => (index < 10 ? `0${index}` : `${index}`));

export const getCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + '/categories/getAllWithFilter', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Graphs data without filters

export const generateYearlyDataArrayWithoutFiltersSum = (dataArray, xAxisArray) => {
  const monthNameToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };

  return xAxisArray?.map((monthName) => {
    const monthNumber = monthNameToNumber[monthName];
    const revenueEntry = dataArray?.find((entry) => entry?._id.month === monthNumber);
    return revenueEntry ? revenueEntry?.totalSum : 0;
  });
};

export const generateMonthlyDataArrayWithoutFiltersSum = (dataArray, xAxisArray) => {
  return xAxisArray?.map((day) => {
    const dayOfMonth = parseInt(day.split(' ')[1], 10); // Extract the day of the month
    const revenueEntry = dataArray?.find((entry) => entry._id.day === dayOfMonth);
    return revenueEntry ? revenueEntry.totalSum : 0;
  });
};

export const generateWeeklyDataArrayWithoutFiltersSum = (dataArray) => {
  const currentMonth = new Date().getMonth() + 1; // Current month is 1-indexed
  const currentYear = new Date().getFullYear();
  const last7DaysArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date(currentDate);
    day.setDate(currentDate.getDate() - i);
    last7DaysArray.push(day.getDate());
  }

  return last7DaysArray?.map((dayNumber) => {
    const revenueEntry = dataArray?.find(
      (entry) =>
        // entry?._id.year === currentYear &&
        // entry?._id.month === currentMonth &&
        entry?._id.day === dayNumber
    );
    return revenueEntry ? revenueEntry.totalSum : 0;
  });
};

export const generateDailyDataArrayWithoutFiltersSum = (dataArray) => {
  const hoursArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  // Initialize an array to store the results
  const resultArray = [];

  // Create a map to efficiently access totalSum by hour
  const hourTotalSumMap = dataArray?.reduce((acc, entry) => {
    const hour = entry?._id?.hour?.toString().padStart(2, '0');
    acc[hour] = entry?.totalSum;
    return acc;
  }, {});

  // Iterate through the hoursArray and add totalSum if available
  hoursArray.forEach((hour) => {
    const totalSum = hourTotalSumMap?.[hour] || 0; // Ensure that hourTotalSumMap is defined
    resultArray.push(totalSum || 0);
  });
  return resultArray;
};

// Numbers functions

export const generateYearlyDataArrayWithoutFiltersNumbers = (dataArray, xAxisArray) => {
  const monthNameToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };

  return xAxisArray?.map((monthName) => {
    const monthNumber = monthNameToNumber[monthName];
    const revenueEntry = dataArray?.find((entry) => entry?._id.month === monthNumber);
    return revenueEntry ? revenueEntry?.numbers : 0;
  });
};

export const generateMonthlyDataArrayWithoutFiltersNumbers = (dataArray, xAxisArray) => {
  return xAxisArray?.map((day) => {
    const dayOfMonth = parseInt(day.split(' ')[1], 10); // Extract the day of the month
    const revenueEntry = dataArray?.find((entry) => entry._id.day === dayOfMonth);
    return revenueEntry ? revenueEntry.numbers : 0;
  });
};

export const generateWeeklyDataArrayWithoutFiltersNumbers = (dataArray) => {
  const currentMonth = new Date().getMonth() + 1; // Current month is 1-indexed
  const currentYear = new Date().getFullYear();
  const last7DaysArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date(currentDate);
    day.setDate(currentDate.getDate() - i);
    last7DaysArray.push(day.getDate());
  }

  return last7DaysArray?.map((dayNumber) => {
    const revenueEntry = dataArray?.find(
      (entry) =>
        // entry?._id.year === currentYear &&
        // entry?._id.month === currentMonth &&
        entry?._id.day === dayNumber
    );
    return revenueEntry ? revenueEntry.numbers : 0;
  });
};

export const generateDailyDataArrayWithoutFiltersNumbers = (dataArray) => {
  const hoursArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  // Initialize an array to store the results
  const resultArray = [];

  // Create a map to efficiently access numbers by hour
  const hourTotalNumbersMap = dataArray?.reduce((acc, entry) => {
    const hour = entry?._id?.hour?.toString().padStart(2, '0');
    acc[hour] = entry?.numbers;
    return acc;
  }, {});

  // Iterate through the hoursArray and add totalNumbers if available
  hoursArray.forEach((hour) => {
    const totalNumbers = hourTotalNumbersMap?.[hour] || 0; // Ensure that hourTotalNumbersMap is defined
    resultArray.push(totalNumbers || 0);
  });
  return resultArray;
};

//total game plays arrays

export const generateYearlyDataArrayGamePlays = (dataArray, xAxisArray, type) => {
  const monthNameToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };

  return xAxisArray?.map((monthName) => {
    const monthNumber = monthNameToNumber[monthName];
    const monthData = dataArray?.find((entry) => entry?._id?.month === monthNumber);
    if (monthData) {
      if (type === 'mode') {
        return {
          type: monthData?.mode,
          totalGamePlays: monthData?.totalGameplays,
          averageGamePlays: monthData?.averageGameplays
        };
      } else {
        return {
          type: monthData?.difficulty,
          totalGamePlays: monthData?.totalGameplays,
          averageGamePlays: monthData?.averageGameplays
        };
      }
    } else {
      return 0;
    }
  });
};

export const generateMonthlyDataArrayGamePlays = (dataArray, xAxisArray, type) => {
  return xAxisArray?.map((day) => {
    const dayOfMonth = parseInt(day.split(' ')[1], 10); // Extract the day of the month
    const revenueEntry = dataArray?.find((entry) => entry?._id?.day === dayOfMonth);
    if (revenueEntry) {
      return type === 'amount' ? revenueEntry?.amount : revenueEntry?.numbers;
    } else {
      return 0;
    }
  });
};

export const generateWeeklyDataArrayGamePlays = (dataArray, type) => {
  const currentMonth = new Date().getMonth() + 1; // Current month is 1-indexed
  const currentYear = new Date().getFullYear();
  const last7DaysArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date(currentDate);
    day.setDate(currentDate.getDate() - i);
    last7DaysArray.push(day.getDate());
  }

  return last7DaysArray?.map((dayNumber) => {
    const revenueEntry = dataArray?.find(
      (entry) =>
        // entry?._id.year === currentYear &&
        // entry?._id.month === currentMonth &&
        entry?._id?.day === dayNumber
    );
    if (revenueEntry) {
      return type === 'amount' ? revenueEntry?.amount : revenueEntry?.numbers;
    } else {
      return 0;
    }
  });
};

export const generateDailyDataArrayGamePlays = (dataArray, type) => {
  const hoursArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  // Initialize an array to store the results
  const resultArray = [];

  // Create a map to efficiently access totalSum by hour
  const hourTotalSumMap = dataArray?.reduce((acc, entry) => {
    const hour = entry?._id?.hour?.toString().padStart(2, '0');
    acc[hour] = type === 'amount' ? entry?.amount : entry?.numbers;
    return acc;
  }, {});

  // Iterate through the hoursArray and add totalSum if available
  hoursArray.forEach((hour) => {
    const totalSum = hourTotalSumMap?.[hour] || 0; // Ensure that hourTotalSumMap is defined
    resultArray.push(totalSum);
  });
  return resultArray;
};
