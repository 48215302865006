import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register3')));

const PaymentWebview = Loadable(lazy(() => import('views/payment')));
const SuccessLoading = Loadable(lazy(() => import('views/payment/success-loading')));
const PaymentSuccess = Loadable(lazy(() => import('views/payment/payment-success')));
const PaymentFailed = Loadable(lazy(() => import('views/payment/payment-failed')));
const PaymentCancelled = Loadable(lazy(() => import('views/payment/payment-cancelled')));
const UpdateSubscription = Loadable(lazy(() => import('views/payment/update-subscription')));

// Download Payment Webview

const DownloadPaymentWebview = Loadable(lazy(() => import('views/download-payment')));
const DownloadSuccessLoading = Loadable(lazy(() => import('views/download-payment/success-loading')));
const DownloadPaymentSuccess = Loadable(lazy(() => import('views/download-payment/payment-success')));
const DownloadPaymentFailed = Loadable(lazy(() => import('views/download-payment/payment-failed')));
const DownloadPaymentCancelled = Loadable(lazy(() => import('views/download-payment/payment-cancelled')));


const TermsAndConditions = Loadable(lazy(() => import('views/terms-and-conditions')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/register-content-creator',
      element: <AuthRegister />
    },
    {
      path: '/payment-webview',
      element: <PaymentWebview />
    },
    {
      path: '/success-loading',
      element: <SuccessLoading />
    },
    {
      path: '/payment-success',
      element: <PaymentSuccess />
    },
    {
      path: '/payment-failed',
      element: <PaymentFailed />
    },
    {
      path: '/payment-cancelled',
      element: <PaymentCancelled />
    },

    // download payment
    {
      path: '/download-payment-webview',
      element: <DownloadPaymentWebview />
    },
    {
      path: '/download-success-loading',
      element: <DownloadSuccessLoading />
    },
    {
      path: '/download-payment-success',
      element: <DownloadPaymentSuccess />
    },
    {
      path: '/download-payment-failed',
      element: <DownloadPaymentFailed />
    },
    {
      path: '/download-payment-cancelled',
      element: <DownloadPaymentCancelled />
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />
    },
    {
      path: '/update-subscription',
      element: <UpdateSubscription />
    },

  ]
};

export default AuthenticationRoutes;
