import { useLocation, Navigate, Outlet } from 'react-router-dom';
const RequireAuth = ({ children }) => {
  // const { role }: any = JSON.parse(localStorage.getItem("careUserData") || "{}");

  const auth = {
    user: localStorage.getItem('userToken')
  };
  const location = useLocation();
  return auth?.user ? children || <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};
export default RequireAuth;
