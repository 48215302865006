import React from 'react';
import { useRoutes, Route, Navigate } from 'react-router-dom';

// Import your route components
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// Check authentication status (you may replace this with your authentication logic)
// const isAuthenticated = () => {
//   if (localStorage?.userToken) {
//     return true; // Change this based on your authentication logic
//   } else {
//     return false;
//   }
// };

// ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {
//   return useRoutes([
//     // Protected route for authenticated users
//     {
//       path: '/',
//       element: isAuthenticated() ? (
//         <MainRoutes />
//       ) : (
//         // Redirect to the login page if not authenticated
//         <Navigate to="/login" replace />
//       ),
//       children: MainRoutes.children
//     },
//     { AuthenticationRoutes }
//   ]);
// }

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, AuthenticationRoutes]);
}
