// assets
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';

// constant
const icons = {
  GroupRoundedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const user_management = {
  id: 'user-management',
  title: 'User Management',
  // caption: 'Pages Caption',
  type: 'group',
  userRoles: ['Super Admin'],
  permissions: ['view-advertisers', 'view-content-creators', 'view-users'],
  children: [
    {
      id: 'advertisers-list',
      title: 'Advertisers',
      type: 'item',
      url: '/advertisers-list',
      icon: icons.GroupRoundedIcon,
      breadcrumbs: false,
      permission: 'view-advertisers'
    },
    {
      id: 'content-creators-list',
      title: 'Content Creators',
      type: 'item',
      url: '/content-creators-list',
      icon: icons.GroupRoundedIcon,
      breadcrumbs: false,
      permission: 'view-content-creators'
    },
    {
      id: 'app-users-list',
      title: 'App Users',
      type: 'item',
      url: '/app-users-list',
      icon: icons.GroupRoundedIcon,
      breadcrumbs: false,
      permission: 'view-users'
    }
  ]
};

export default user_management;
